"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.func = exports.suspendSaving = void 0;
const _1 = require(".");
exports.suspendSaving = { suspendSaving: true };
(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    _1.libraryForm.value = (_a = localStorage.getItem('library')) !== null && _a !== void 0 ? _a : '';
    _1.vowelForm.value = (_b = localStorage.getItem('vowel')) !== null && _b !== void 0 ? _b : '';
    _1.vowelModeForm.value = (_c = localStorage.getItem('vowelMode')) !== null && _c !== void 0 ? _c : '';
    _1.wordIncludeForm.value = (_d = localStorage.getItem('wordInclude')) !== null && _d !== void 0 ? _d : '';
    _1.wordIncludeModeForm.value = (_e = localStorage.getItem('wordIncludeMode')) !== null && _e !== void 0 ? _e : '';
    _1.wordExcludeForm.value = (_f = localStorage.getItem('wordExclude')) !== null && _f !== void 0 ? _f : '';
    _1.wordExcludeModeForm.value = (_g = localStorage.getItem('wordExcludeMode')) !== null && _g !== void 0 ? _g : '';
    _1.queryNumberForm.value = (_h = localStorage.getItem('queryNumber')) !== null && _h !== void 0 ? _h : '';
    _1.accessKeyForm.value = (_j = localStorage.getItem('accessKey')) !== null && _j !== void 0 ? _j : '';
    _1.useThirdPartyApiCheckbox.selected = localStorage.getItem('useThirdPartyApi') == 'true';
    _1.apiUriForm.value = (_k = localStorage.getItem('apiUri')) !== null && _k !== void 0 ? _k : '';
    if (_1.useThirdPartyApiCheckbox.selected == true) {
        _1.apiUriForm.style.display = '';
    }
    else {
        _1.apiUriForm.style.display = 'none';
    }
    exports.suspendSaving.suspendSaving = false;
})();
let func = () => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        if (!exports.suspendSaving.suspendSaving)
            setTimeout(() => {
                let data = {
                    library: _1.libraryForm.value,
                    vowel: _1.vowelForm.value,
                    vowelMode: _1.vowelModeForm.value,
                    wordInclude: _1.wordIncludeForm.value,
                    wordIncludeMode: _1.wordIncludeModeForm.value,
                    wordExclude: _1.wordExcludeForm.value,
                    wordExcludeMode: _1.wordExcludeModeForm.value,
                    queryNumber: _1.queryNumberForm.value,
                    accessKey: _1.accessKeyForm.value,
                    useThirdPartyApi: _1.useThirdPartyApiCheckbox.selected.toString(),
                    apiUri: _1.apiUriForm.value,
                };
                for (let p in data) {
                    localStorage.setItem(p, data[p]);
                }
                resolve();
            }, 10);
        else
            resolve();
    });
});
exports.func = func;
for (let elm of document.getElementsByClassName('modify-trigger')) {
    if (typeof (elm.selected) == 'boolean') {
        elm.onclick = exports.func;
    }
    else {
        console.log(elm.tagName);
        elm.onchange = exports.func;
    }
}
