"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryCountField = exports.apiUriForm = exports.useThirdPartyApiCheckbox = exports.resultList = exports.resultError = exports.circularProgress = exports.accessKeyForm = exports.queryNumberForm = exports.wordExcludeModeForm = exports.wordExcludeForm = exports.wordIncludeModeForm = exports.wordIncludeForm = exports.vowelModeForm = exports.vowelForm = exports.libraryForm = exports.queryButton = exports.ncmPrefix = exports.apiHost = void 0;
require("@material/mwc-button");
require("@material/mwc-dialog");
require("@material/mwc-icon-button");
require("@material/mwc-textfield");
require("@material/mwc-top-app-bar-fixed");
require("@material/mwc-select");
require("@material/mwc-checkbox");
require("@material/mwc-radio");
require("@material/mwc-list");
require("@material/mwc-list/mwc-check-list-item");
require("@material/mwc-circular-progress");
require("@material/mwc-snackbar");
exports.apiHost = 'https://lyric-api.myxrcrs.cn';
exports.ncmPrefix = 'https://music.163.com/#/song?id=';
exports.queryButton = document.getElementById('btn-query');
exports.libraryForm = document.getElementById('select-library');
exports.vowelForm = document.getElementById('form-vowel');
exports.vowelModeForm = document.getElementById('select-vowel-mode');
exports.wordIncludeForm = document.getElementById('form-word-include');
exports.wordIncludeModeForm = document.getElementById('select-word-include-mode');
exports.wordExcludeForm = document.getElementById('form-word-exclude');
exports.wordExcludeModeForm = document.getElementById('select-word-exclude-mode');
exports.queryNumberForm = document.getElementById('form-turns');
exports.accessKeyForm = document.getElementById('form-accesskey');
exports.circularProgress = document.getElementById('result-progress');
exports.resultError = document.getElementById('result-error');
exports.resultList = document.getElementById('result-list');
exports.useThirdPartyApiCheckbox = document.getElementById('check-use-3rd-party-api');
exports.apiUriForm = document.getElementById('form-api-uri');
exports.queryCountField = document.getElementById('query-count');
require("./autosave");
require("./core");
