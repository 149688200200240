"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiHost = void 0;
const _1 = require(".");
function getApiHost() {
    if (_1.useThirdPartyApiCheckbox.selected) {
        return _1.apiUriForm.value;
    }
    else {
        return _1.apiHost;
    }
}
exports.getApiHost = getApiHost;
