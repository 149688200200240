"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const PayloadUtils_1 = require("../common/PayloadUtils");
const logger_1 = require("./logger");
const invokeQuery_1 = require("./invokeQuery");
const _1 = require(".");
const ResultSection_1 = require("./ResultSection");
const syncCorpusList_1 = require("./syncCorpusList");
const autosave_1 = require("./autosave");
(() => {
    let f = _1.useThirdPartyApiCheckbox.onclick;
    _1.useThirdPartyApiCheckbox.onclick = null;
    _1.useThirdPartyApiCheckbox.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (f)
            yield f();
        if (_1.useThirdPartyApiCheckbox.selected == true) {
            _1.apiUriForm.style.display = '';
        }
        else {
            _1.apiUriForm.style.display = 'none';
        }
    });
})();
(() => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    console.log(1);
    let list = yield (0, syncCorpusList_1.syncCorpusList)();
    if (!list)
        return;
    autosave_1.suspendSaving.suspendSaving = true;
    _1.libraryForm.append(...list.map(entry => {
        let elm = document.createElement('mwc-list-item');
        elm.value = entry.name;
        elm.textContent = entry.label;
        return elm;
    }));
    _1.libraryForm.value = (_a = localStorage.getItem('library')) !== null && _a !== void 0 ? _a : '';
    _1.libraryForm.onchange = autosave_1.func;
    autosave_1.suspendSaving.suspendSaving = false;
}))();
_1.queryButton.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    let allValid = true;
    for (let elm of [_1.libraryForm, _1.vowelForm, _1.vowelModeForm, _1.wordIncludeForm, _1.wordIncludeModeForm, _1.wordExcludeForm, _1.wordExcludeModeForm, _1.queryNumberForm, _1.accessKeyForm]) {
        if (!elm.validity.valid) {
            elm.reportValidity();
            allValid = false;
        }
    }
    if (allValid) {
        ResultSection_1.ResultSection.clearResult();
        ResultSection_1.ResultSection.CircularProgress.show();
        try {
            let res = yield (0, invokeQuery_1.invokeQuery)(parseQuery());
            ResultSection_1.ResultSection.CircularProgress.hide();
            _1.resultList.append(...res.body.map(entry => {
                let item = document.createElement('mwc-list-item');
                item.twoline = true;
                item.setAttribute('entry-data', JSON.stringify(entry));
                let firstLine = document.createElement('span');
                firstLine.textContent = entry.line;
                item.appendChild(firstLine);
                let secondLine = document.createElement('span');
                secondLine.textContent = [entry.pinyin, entry.title, entry.singer].join(' - ');
                secondLine.slot = 'secondary';
                item.appendChild(secondLine);
                item.onclick = () => {
                    logger_1.logger.dialog('详细信息', [`歌词：${entry.line}`, `拼音：${entry.pinyin}`, `韵尾：${entry.vowel}`, `歌名：${entry.title}`, `作者：${entry.singer}`], { label: 'OK', action: () => { } }, entry.id ? { label: '访问网易云音乐', action: () => { window.open(_1.ncmPrefix + entry.id, '_blank'); } } : undefined);
                };
                return item;
            }));
            ResultSection_1.ResultSection.showQueryCount(_1.queryNumberForm.value, res.body.length, res.queryNumber.total, res.queryNumber.available);
        }
        catch (e) {
            ResultSection_1.ResultSection.clearResult();
            ResultSection_1.ResultSection.showError(e);
        }
    }
});
function parseQuery() {
    let query = {
        library: _1.libraryForm.value,
        vowel: _1.vowelForm.value.split(' ').filter(v => v),
        vowelMode: (0, PayloadUtils_1.convertVowelMode)(_1.vowelModeForm.value),
        wordInclude: _1.wordIncludeForm.value.split(' ').filter(v => v),
        wordIncludeMode: (0, PayloadUtils_1.convertWordMode)(_1.wordIncludeModeForm.value),
        wordExclude: _1.wordExcludeForm.value.split(' ').filter(v => v),
        wordExcludeMode: (0, PayloadUtils_1.convertWordMode)(_1.wordExcludeModeForm.value),
        queryNumber: parseInt(_1.queryNumberForm.value),
        accessKey: _1.accessKeyForm.value,
    };
    return query;
}
