"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultSection = void 0;
const _1 = require(".");
var ResultSection;
(function (ResultSection) {
    function clearResult() {
        _1.resultList.innerHTML = '';
        _1.resultError.innerHTML = '';
        _1.queryCountField.innerHTML = '';
        CircularProgress.hide();
    }
    ResultSection.clearResult = clearResult;
    let CircularProgress;
    (function (CircularProgress) {
        function show() {
            _1.circularProgress.style.display = 'block';
        }
        CircularProgress.show = show;
        function hide() {
            _1.circularProgress.style.display = 'none';
        }
        CircularProgress.hide = hide;
    })(CircularProgress = ResultSection.CircularProgress || (ResultSection.CircularProgress = {}));
    function showError(e) {
        _1.resultError.textContent = e.toString();
    }
    ResultSection.showError = showError;
    function showQueryCount(count, actualCount, total, available) {
        let s = `${count}请求，${actualCount}已获取，`;
        if (total == -1) {
            s += '每日可查询无限条。';
        }
        else {
            s += `每日可查询${total}条，今日剩余${available}条。`;
        }
        _1.queryCountField.textContent = s;
    }
    ResultSection.showQueryCount = showQueryCount;
})(ResultSection = exports.ResultSection || (exports.ResultSection = {}));
