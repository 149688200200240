"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListStatus = exports.QueryResponseStatus = exports.convertWordMode = exports.WordMode = exports.convertVowelMode = exports.VowelMode = exports.vowelList = void 0;
exports.vowelList = ['-i', 'a', 'o', 'e', '^e', 'i', 'u', 'v', 'er', 'ai', 'ei', 'ao', 'ou', 'an', 'en', 'n', 'ang', 'eng', 'ong', 'ing'];
var VowelMode;
(function (VowelMode) {
    VowelMode[VowelMode["Similar"] = 0] = "Similar";
    VowelMode[VowelMode["Same"] = 1] = "Same";
})(VowelMode = exports.VowelMode || (exports.VowelMode = {}));
function convertVowelMode(s) {
    switch (s) {
        case 'same': return VowelMode.Same;
        default: return VowelMode.Similar;
    }
}
exports.convertVowelMode = convertVowelMode;
var WordMode;
(function (WordMode) {
    WordMode[WordMode["Any"] = 0] = "Any";
    WordMode[WordMode["Head"] = 1] = "Head";
    WordMode[WordMode["Tail"] = 2] = "Tail";
    WordMode[WordMode["Advanced"] = 3] = "Advanced";
})(WordMode = exports.WordMode || (exports.WordMode = {}));
function convertWordMode(s) {
    switch (s) {
        case 'head': return WordMode.Head;
        case 'tail': return WordMode.Tail;
        case 'advanced': return WordMode.Advanced;
        default: return WordMode.Any;
    }
}
exports.convertWordMode = convertWordMode;
var QueryResponseStatus;
(function (QueryResponseStatus) {
    QueryResponseStatus[QueryResponseStatus["OK"] = 0] = "OK";
    QueryResponseStatus[QueryResponseStatus["PermissionDenied"] = 1] = "PermissionDenied";
    QueryResponseStatus[QueryResponseStatus["QueryNumberLimitExceeded"] = 2] = "QueryNumberLimitExceeded";
    QueryResponseStatus[QueryResponseStatus["FormatError"] = 3] = "FormatError";
    QueryResponseStatus[QueryResponseStatus["CorpusLibraryNotExist"] = 4] = "CorpusLibraryNotExist";
    QueryResponseStatus[QueryResponseStatus["ServerError"] = 5] = "ServerError";
})(QueryResponseStatus = exports.QueryResponseStatus || (exports.QueryResponseStatus = {}));
var ListStatus;
(function (ListStatus) {
    ListStatus[ListStatus["OK"] = 0] = "OK";
    ListStatus[ListStatus["ServerError"] = 1] = "ServerError";
})(ListStatus = exports.ListStatus || (exports.ListStatus = {}));
